<template>
    <div>
        <el-page-header
            content="网站设置"
            icon=""
            title="其它管理"
        />
		<!-- 滚动条 -->
		<el-scrollbar style="height:600px;">
        <el-form
            ref="wzszFormRef"
            :model="wzszForm"
            :rules="wzszFormRules"
            label-width="100px"
            class="demo-ruleForm"
        >
            <!-- <el-form-item
                label="网站名称"
                prop="webname"
            >
                <el-input v-model="wzszForm.webname" />
            </el-form-item> -->
			<!-- <el-form-item
                label="首页关键词"
                prop="wzszname"
            >
                <el-input v-model="wzszForm.wzszname" />
            </el-form-item> -->
			<el-form-item
			    label="底部内容"
			    prop="content"
			>
			<editor @event="handleChange2" :content="wzszForm.content" v-if="wzszForm.content"/>
			</el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
					v-show="show1"
                    @click="submitForm()"
                >添加内容</el-button>
				<el-button
				    type="primary"
				    @click="submitForm2()"
				>保存修改</el-button>
            </el-form-item>
        </el-form>
		</el-scrollbar>
    </div>
</template>

<script setup>
import { ref, reactive,onMounted } from "vue"
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import editor from '@/components/editor/Editor'
import {useRouter} from 'vue-router'
import axios from 'axios'
import {getCurrentInstance} from 'vue';
const { proxy } = getCurrentInstance();
const show1 = ref(true)
const wzszFormRef = ref()
const wzszForm = reactive({
 // webname:'',
 // wzszname: "",
  content:"",
  file: null,
});

const wzszFormRules = reactive({
  // webname: [{ required: true, message: "请输入网站名称", trigger: "blur" }],
 // wzszname: [{ required: true, message: "请输入首页关键词", trigger: "blur" }],
  content: [{ required: true, message: "请输入底部联系方式", trigger: "blur" }],
});
// 文本数据传输
	const handleChange2 = data => {
	wzszForm.content = data;
	};
//每次选择完图片之后的回调
const handleChange = file => {
 wzszForm.avatar = URL.createObjectURL(file);
 wzszForm.file = file;
};
const router = useRouter()
const submitForm = ()=>{
   wzszFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
			console.log(wzszForm);
            await upload("/adminapi/wzsz/add",wzszForm)
        }
    })
}
// const wzszForm = ref([]);
onMounted(() => {
  getTableData();
});
// 获取全部数据
const getTableData = async () => {
  const res = await axios.get("/adminapi/wzsz/list");
  console.log(res.data)
  Object.assign(wzszForm,res.data.data[0])
  if(wzszForm.webname!=""){
	   show1.value=false
  }
};
// 保存修改
const submitForm2 = ()=>{
   wzszFormRef.value.validate(async (valid)=>{
       if(valid){
           // 1- 更新后端
		   console.log(wzszForm);
           await upload("/adminapi/wzsz/list",wzszForm)
            // 3-获取table数据
           getTableData()
       }
   })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
