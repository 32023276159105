<template>
    <div>
        <el-card>
            <el-page-header
                content="新闻列表"
                icon=""
                title="新闻中心"
            />

            <el-table
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="newsname"
                    label="名称"
                    width="180"
                />
                <el-table-column
                    label="图片"
                    width="180"
                >
                    <template #default="scope">
                        <div v-if="scope.row.avatar">
                            <el-avatar
							    shape="square"
                                :size="50" style="width: 65px; height: 50px;"
                                :src="proxy.$key+scope.row.avatar"
                            ></el-avatar>
                        </div>
                        <div v-else>
                            <el-avatar
                                :size="50" style="width: 65px; height: 50px;"
                                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                            />
                        </div>
                    </template>
                </el-table-column>
				<el-table-column
				    prop="newstxt"
				    label="简介"
				    width="200"
				/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button
                            size="small"
                            @click="handleEdit(scope.row)"
                        >编辑</el-button>
                        <el-popconfirm
                            title="你确定要删除吗?"
                            confirmButtonText="确定"
                            cancelButtonText="取消"
                            @confirm="handleDelete(scope.row)"
                        >
                            <template #reference>
                                <el-button
                                    size="small"
                                    type="danger"
                                >删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
<!-- 弹出框 -->
        <el-dialog
            v-model="dialogVisible"
            title="编辑新闻"
            width="50%"
        >
            <el-form
                ref="newsFormRef"
                :model="newsForm"
                :rules="newsFormRules"
                label-width="80px"
                class="demo-ruleForm"
            >
                <el-form-item
                    label="名称"
                    prop="newsname"
                >
                    <el-input v-model="newsForm.newsname" />
                </el-form-item>
				
                <el-form-item
                    label="简介"
                    prop="newstxt"
                >
                    <el-input
                        v-model="newsForm.newstxt"
                        type="textarea"
                    />
                </el-form-item>
				<el-form-item
				    label="内容"
				    prop="content"
				>
				<editor @event="handleChange2" :content="newsForm.content" v-if="showEditor"/>
				</el-form-item>
				<el-form-item label="图片" prop="avatar">
				<Upload :avatar="newsForm.avatar" @kerwinchange="handleChange"/>
				</el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button
                        type="primary"
                        @click="handleEditConfirm()"
                    >确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import { ref, onMounted,reactive } from "vue";
import axios from "axios";
import editor from '@/components/editor/Editor'
import upload from "@/util/upload";
import Upload from '@/components/upload/Upload'
import {getCurrentInstance} from 'vue';
const { proxy } = getCurrentInstance();
const showEditor = ref(false)
const dialogVisible = ref(false);
const newsFormRef = ref();
let newsForm = reactive({
  content:'',
  newsname: "",
  newstxt: "",
  avatar: "",
  file: null
});
const newsFormRules = reactive({
  content: [{ required: true, message: "请输入内容", trigger: "blur" }],
  newsname: [{ required: true, message: "请输入名字", trigger: "blur" }],
  avatar: [{ required: true, message: "请输图片", trigger: "blur" }],
  newstxt: [{ required: true, message: "请输入密码", trigger: "blur" }],
});
const tableData = ref([]);
onMounted(() => {
  getTableData();
});
// 获取全部数据
const getTableData = async () => {
  const res = await axios.get("/adminapi/news/list");
  tableData.value = res.data.data;
};
// 文本数据传输
	const handleChange2 = data => {
	  newsForm.content = data;
	};
// 编辑回调
const handleEdit = async (item) => {
  const res1 = await axios.get(`/adminapi/news/list/${item._id}`)
  Object.assign(newsForm,res1.data.data[0])
  newsForm.value=res1.data.data[0]
  dialogVisible.value = true;
  showEditor.value = true; 
};
//每次选择完图片之后的回调
	const handleChange = file => {
		newsForm.avatar = URL.createObjectURL(file);
		newsForm.file = file;
	};
//编辑确认回调
const handleEditConfirm = ()=>{
    newsFormRef.value.validate(async (valid)=>{
        if(valid){
            // 1- 更新后端
            await upload("/adminapi/news/list",newsForm)
             //2- dialog隐藏
            dialogVisible.value = false
             //3-获取table数据
            getTableData()
        }
    })
}
// 删除选中数据
const handleDelete = async (item)=>{
    // console.log(item)
	const idavatar = reactive({
		_id: item._id, //id
		avatar: item.avatar, //图片名称
	})
    await axios.delete("/adminapi/news/list",{
		params:idavatar
	})
    await getTableData()
}
</script>
<style lang="scss" scoped>
.el-table {
  margin-top: 50px;
}
::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>
