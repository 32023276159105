<template>
	<el-scrollbar style="height:700px;">
	 <el-row :gutter="20">
	    <el-col :span="24"><div style=""></div></el-col>
	
	
<el-col :span="24"><div>
		<el-card>
			<el-page-header content="友情链接" icon="" title="首页管理" />
			<!-- 滚动条 -->
			<el-scrollbar style="height:500px;">
				<el-table :data="tableData2" style="width: 100%">
					<el-table-column
					    prop="yqname"
					    label="链接名称"
					    width="150"
					/>
					<el-table-column
					    prop="yqlink"
					    label="链接地址"
					    width="150"
					/>
					<el-table-column label="操作">
						<template #default="scope">
							<el-button size="small" @click="handleEdit2(scope.row)">编辑</el-button>
							<el-popconfirm title="你确定要删除吗?" confirmButtonText="确定" cancelButtonText="取消"
								@confirm="handleDelete2(scope.row)">
								<template #reference>
									<el-button size="small" type="danger">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-scrollbar>
		</el-card>
		<!-- 弹出框 -->
		<el-dialog v-model="dialogVisible3" title="编辑信息" width="50%">
			<el-form ref="yqFormRef" :model="yqForm" :rules="yqFormRules" label-width="80px"
				class="demo-ruleForm">
				<el-form
				    ref="yqFormRef"
				    :model="yqForm"
				    :rules="yqFormRules"
				    label-width="80px"
				    class="demo-ruleForm"
				>
				<el-form-item
				    label="链接名称"
				    prop="yqname"
				>
				    <el-input v-model="yqForm.yqname" />
				</el-form-item>
				<el-form-item
				    label="链接地址"
				    prop="yqlink"
				>
				    <el-input v-model="yqForm.yqlink" />
				</el-form-item>
				</el-form>
				
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible3 = false">取消</el-button>
					<el-button type="primary" @click="handleEditConfirm2()">确认</el-button>
				</span>
			</template>
		</el-dialog>
	</div></el-col>
	  </el-row>
	<!-- 微信QQ -->
	<div>
			<el-card>
				<el-page-header content="联系方式" icon="" title="首页管理" />
				<!-- 滚动条 -->
				<el-scrollbar style="height:500px;">
					<el-table :data="tableData5" style="width: 100%">
						<el-table-column prop="landline" label="座机" width="150" />
						<el-table-column prop="qqh" label="QQ号" width="180" />
						<el-table-column prop="wxh" label="微信号" width="180" />
						<el-table-column label="图片" width="180">
							<template #default="scope">
								<div v-if="scope.row.avatar">
									<el-avatar shape="square" :size="50" style="width: 65px; height: 50px;"
										:src="proxy.$key+scope.row.avatar">1</el-avatar>
								</div>
								<div v-else>
									<el-avatar :size="50" style="width: 65px; height: 50px;"
										src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template #default="scope">
								<el-button size="small" @click="handleEdit5(scope.row)">编辑</el-button>
								<el-popconfirm title="你确定要删除吗?" confirmButtonText="确定" cancelButtonText="取消"
									@confirm="handleDelete5(scope.row)">
									<template #reference>
										<el-button size="small" type="danger">删除</el-button>
									</template>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
				</el-scrollbar>
			</el-card>
			<!-- 弹出框 -->
			<el-dialog v-model="dialogVisible5" title="编辑信息" width="50%">
				<el-form ref="wxqqpFormRef" :model="wxqqpForm" :rules="wxqqpFormRules" label-width="80px"
					class="demo-ruleForm">
					<el-form-item label="座机号" prop="wxqqpname">
						<el-input v-model="wxqqpForm.landline" />
					</el-form-item>
					<el-form-item label="QQ号" prop="wxqqpnumber">
						<el-input v-model="wxqqpForm.qqh" />
					</el-form-item>
					<el-form-item label="微信号" prop="wxqqpnumber">
						<el-input v-model="wxqqpForm.wxh" />
					</el-form-item>
					<el-form-item label="图片" prop="avatar">
						<Upload :avatar="wxqqpForm.avatar" @kerwinchange="handleChange5" />
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisible5 = false">取消</el-button>
						<el-button type="primary" @click="handleEditConfirm5()">确认</el-button>
					</span>
				</template>
			</el-dialog>
		</div>
		</el-scrollbar>
</template>
<script setup>
	import {ref,onMounted,reactive} from "vue";
	import { Plus } from '@element-plus/icons-vue'
	import axios from "axios";
	import upload from "@/util/upload";
	import Upload from '@/components/upload/Upload'
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const dialogVisible1 = ref(false)
	const dialogVisible = ref(false);
	const dialogVisible12 = ref(false)
	const dialogVisible3 = ref(false);
	const yqFormRef = ref();
	const gssyFormRef = ref();
	let gssyForm = reactive({
		avatar: "",
		file: null
	});
	const gssyFormRules = reactive({
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	//每次选择完图片之后的回调
	const handleChange = file => {
		gssyForm.avatar = URL.createObjectURL(file);
		gssyForm.file = file;
	};
	const tableData = ref([]);
	onMounted(() => {
		getTableData();
	});
	// 获取全部数据
	const getTableData = async () => {
		// 获取列表数据
		const res = await axios.get("/adminapi/gssy/list",);
		console.log(res.data)
		tableData.value = res.data.data;
		
	};
	// 编辑回调
	const handleEdit = async (item) => {
		const res = await axios.get(`/adminapi/gssy/list/${item._id}`)
		Object.assign(gssyForm, res.data.data[0])
		dialogVisible.value = true;
	};
	//编辑确认回调
	const handleEditConfirm = () => {
		gssyFormRef.value.validate(async (valid) => {
			if (valid) {
				// console.log(gssyForm);
				// 1- 更新后端
				await upload("/adminapi/gssy/list", gssyForm)
				//2- dialog隐藏
				dialogVisible.value = false
				//3-获取table数据
				getTableData()
			}
		})
	}
	// 删除选中数据
	const handleDelete = async (item) => {
		// console.log(item)
		const idavatar = reactive({
			_id: item._id, //id
			avatar: item.avatar, //图片名称
		})
		await axios.delete("/adminapi/gssy/list",{
			params:idavatar
		})
		await getTableData()
	}
	
	// 友情链接
	let yqForm = reactive({
		yqname: "",
		yqlink: ""
	});
	const yqFormRules = reactive({
		yqname: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
		yqlink: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	const tableData2 = ref([]);
	onMounted(() => {
		gettableData2();
	});
	// 获取全部数据
	const gettableData2 = async () => {
		// 获取列表数据
		const res = await axios.get("/adminapi/yq/list",);
		console.log(res.data)
		tableData2.value = res.data.data;
		
	};
	// 编辑回调
	const handleEdit2 = async (item) => {
		const res = await axios.get(`/adminapi/yq/list/${item._id}`)
		Object.assign(yqForm, res.data.data[0])
		dialogVisible3.value = true;
	};
	//编辑确认回调
	const handleEditConfirm2 = () => {
		yqFormRef.value.validate(async (valid) => {
			if (valid) {
				// console.log(yqForm);
				// 1- 更新后端
				await axios.post("/adminapi/yq/list",yqForm)
				//2- dialog隐藏
				dialogVisible3.value = false
				//3-获取table数据
				gettableData2()
			}
		})
	}
	// 删除选中数据
	const handleDelete2 = async (item) => {
		// console.log(item)
		await axios.delete(`/adminapi/yq/list/${item._id}`)
		await gettableData2()
	}
	// 微信QQ
	const dialogVisible5 = ref(false);
		const wxqqpFormRef = ref();
		let wxqqpForm = reactive({
	        landline: "",
	  qqh: "",
	  wxh: "",
	  avatar: "",
	  file: null,
		});
		const wxqqpFormRules = reactive({
			landline: [{ required: true, message: "请输入座机号", trigger: "blur" }],
	  qqh: [{ required: true, message: "请输入QQ号", trigger: "blur" }],
	  wxh: [{ required: true, message: "请输入微信号", trigger: "blur" }],
	  avatar: [{ required: true, message: "请上传微信图片", trigger: "blur" }]
		});
		const tableData5 = ref([]);
		onMounted(() => {
			getTableData5();
		});
		// 获取全部数据
		const getTableData5 = async () => {
			// 获取列表数据
			const res = await axios.get("/adminapi/wxqq/list",);
			console.log(res.data)
			tableData5.value = res.data.data;
		};
		// 编辑回调
		const handleEdit5 = async (item) => {
			const res = await axios.get(`/adminapi/wxqq/list/${item._id}`)
			Object.assign(wxqqpForm, res.data.data[0])
			dialogVisible5.value = true;
		};
		//每次选择完图片之后的回调
		const handleChange5 = file => {
			wxqqpForm.avatar = URL.createObjectURL(file);
			wxqqpForm.file = file;
		};
		//编辑确认回调
		const handleEditConfirm5 = () => {
			wxqqpFormRef.value.validate(async (valid) => {
				if (valid) {
					// console.log(wxqqpForm);
					// 1- 更新后端
					await upload("/adminapi/wxqq/list", wxqqpForm)
					//2- dialog隐藏
					dialogVisible5.value = false
					//3-获取table数据
					getTableData5()
				}
			})
		}
		// 删除选中数据
		const handleDelete5 = async (item) => {
			// console.log(item)
			const idavatar = reactive({
				_id: item._id, //id
				avatar: item.avatar, //图片名称
			})
			
			await axios.delete("/adminapi/wxqq/list",{
		    params:idavatar
	     })
			await getTableData5()
		}
</script>
<style lang="scss" scoped>
	.pagin {
		margin: 10px 220px;
	}

	.el-table {
		margin-top: 50px;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>