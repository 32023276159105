<template>
	<el-aside :width="$store.state.isCollapsed?'64px':'240px'">
		<el-menu 
		:router="true" 
		:default-active="route.fullPath" 
		:collapse="$store.state.isCollapsed"
		:collapse-transition="false">
			<el-menu-item index="/index">
			    <el-icon><home-filled /></el-icon>
			    <span>首页</span>
			</el-menu-item>
			<el-sub-menu index="/zhgl" v-admin>
				<template #title>
					<el-icon><Reading /></el-icon>
					<span>账号管理</span>
				</template>
				<el-menu-item index="/zhgl/zhgladd">添加账号</el-menu-item>
				<el-menu-item index="/zhgl/zhgllist">账号列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/gssy">
				<template #title>
					<el-icon><Notification /></el-icon>
					<span>公司首页</span>
				</template>
				<el-menu-item index="/gssy/gssyadd">添加首页数据</el-menu-item>
				<el-menu-item index="/gssy/gssylist">首页数据列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/gsjj">
				<template #title>
					<el-icon><EditPen /></el-icon>
					<span>公司简介</span>
				</template>
				<el-menu-item index="/gsjj/gsjjadd">添加简介数据</el-menu-item>
				<el-menu-item index="/gsjj/gsjjlist">简介列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/product">
				<template #title>
					<el-icon><CameraFilled /></el-icon>
					<span>服务项目</span>
				</template>
				<el-menu-item index="/product/productadd">添加数据</el-menu-item>
				<el-menu-item index="/product/productlist">产品列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/byzs">
				<template #title>
					<el-icon><PictureFilled /></el-icon>
					<span>维修案例</span>
				</template>
				<el-menu-item index="/byzs/byzsscadd">添加红木案列</el-menu-item>
				<el-menu-item index="/byzs/byzssclist">红木案列列表</el-menu-item>
				<el-menu-item index="/byzs/byzscxadd">添加实木案例</el-menu-item>
				<el-menu-item index="/byzs/byzscxlist">实木案例列表</el-menu-item>
				<el-menu-item index="/byzs/bgwxadd">添加办公案例</el-menu-item>
				<el-menu-item index="/byzs/bgwxlist">办公案例列表</el-menu-item>
				<el-menu-item index="/byzs/bswxadd">添加板式案例</el-menu-item>
				<el-menu-item index="/byzs/bswxlist">板式案例列表</el-menu-item>
				<el-menu-item index="/byzs/dbwxadd">添加大板案例</el-menu-item>
				<el-menu-item index="/byzs/dbwxlist">大板案例列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/news">
				<template #title>
					<el-icon><MessageBox /></el-icon>
					<span>新闻中心</span>
				</template>
				<el-menu-item index="/news/newsadd">添加新闻数据</el-menu-item>
				<el-menu-item index="/news/newslist">新闻列表</el-menu-item>
			</el-sub-menu>
			
			
			<el-sub-menu index="/lxwm">
				<template #title>
					<el-icon><Phone /></el-icon>
					<span>联系我们</span>
				</template>
				<el-menu-item index="/lxwm/lxwmadd">添加数据</el-menu-item>
				<el-menu-item index="/lxwm/lxwmlist">数据列表</el-menu-item>
			</el-sub-menu>
			 <el-sub-menu index="/gggl">
			 	<template #title>
			 		<el-icon><More /></el-icon>
			 		<span>其他管理</span>
			 	</template>
			 	<el-menu-item index="/qtgl/gggl">广告管理</el-menu-item>
			 	<el-menu-item index="/qtgl/wzsz">网站设置</el-menu-item>
			 </el-sub-menu>
		</el-menu>
	</el-aside>
</template>

<script setup>
	import {useRoute} from 'vue-router'
	import {useStore } from 'vuex'
	import {HomeFilled,Avatar,Notification,EditPen,MessageBox,CameraFilled,PictureFilled,SuitcaseLine,Tools,Tickets,Phone,More,Reading} from '@element-plus/icons-vue'
	const store = useStore()
	const route = useRoute()
	const vAdmin = {
	    mounted(el){
	        if(store.state.userInfo.role!==1){
	            el.parentNode.removeChild(el)
	        }
	    }
	}
</script>

<style lang="scss" scoped>
	.el-aside {
		height: 100vh;

		.el-menu {
			height: 100vh;
		}
	}
</style>