<template>
	<div>
		<el-page-header content="数据列表" icon="" title="数据管理" />
		<el-form
		    ref="productFormRef"
		    :model="productForm"
			:rules="productFormRules"
		    label-width="80px"
		    class="demo-ruleForm"
		>
			<el-form-item
			    label="内容"
			    prop="content"
			>
			<editor @event="handleChange" :content="productForm.content" v-if="showEditor"/>
			</el-form-item>
			<el-form-item>
			    <el-button
			        type="primary"
			        @click="submitForm()" 
			    >更新数据</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script setup>
	import { ref, onMounted,reactive } from "vue";
	import axios from "axios";
	import editor from '@/components/editor/Editor'
	const showEditor = ref(false)
	const productFormRef = ref();
	let productForm = reactive({
	  productname: "",
	  content: "",
	});
	const productFormRules = reactive({
	 content: [{ required: true, message: "请输入内容", trigger: "blur" }],
	 productname: [{ required: true, message: "请输入标题", trigger: "blur" }],
	});
	// 文本数据传输
	const handleChange = data => {
	  productForm.content = data;
	};
	onMounted(()=>{
		getTableData()
	})
	const getTableData = async()=>{
		const res = await axios.get(`/adminapi/product/list`)
		console.log(res.data);
		Object.assign(productForm,res.data.data[0]);
		showEditor.value = true; 
	}
	const submitForm = async() =>{
		const res = await axios.post(`/adminapi/product/list`,productForm)
		getTableData()
	}
</script>

<style>
	.demo-ruleForm {
	  margin-top: 50px;
	}
</style>