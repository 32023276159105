import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import Particles from "particles.vue3"
import "@/util/axios.config"
const app=createApp(App)
app.config.globalProperties.$key = "/img";// 定义全局变量(线上模式)
// app.config.globalProperties.$key = "http://localhost:3100"//（线下模式）
app.use(Particles).use(store).use(ElementPlus,{locale}).use(router).mount('#app')
